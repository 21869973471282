import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import { List, ListItem, Text } from "@kiwicom/orbit-components"

import Container from "components/container"
import { EmberCard, EmberCardSection } from "components/generic/ember-card"
import Hero from "components/hero"
import { DriveLayout } from "components/layout-custom"

const Page = () => (
  <DriveLayout title="CCTV Overview">
    <Hero title="CCTV Overview" />
    <Container size="medium">
      <EmberCard>
        <EmberCardSection>
          <Text>
            All of our coaches have a 7 camera onboard CCTV system. The system
            only records video and not audio. The cameras are located both
            inside and outside of the vehicle. The cameras can see the following
            views:
          </Text>
        </EmberCardSection>
        <EmberCardSection
          title="Front Facing"
          description="This is the forward looking view straight out the windscreen"
        >
          <StaticImage src="./images/forward.jpg" alt="Forward Looking View" />
        </EmberCardSection>
        <EmberCardSection
          title="Front Seats"
          description="This shows the front bank of seats"
        >
          <StaticImage src="./images/front-seats.jpg" alt="Front Seats View" />
        </EmberCardSection>
        <EmberCardSection
          title="Front Door"
          description="Shows the people boarding and the iPad, tachograph and radio area"
        >
          <StaticImage src="./images/front-door.jpg" alt="Front Door View" />
        </EmberCardSection>
        <EmberCardSection
          title="Middle Door"
          description="Shows where people are exiting the vehicle"
        >
          <StaticImage src="./images/middle.jpg" alt="Middle Door View" />
        </EmberCardSection>
        <EmberCardSection
          title="Rear"
          description="The view looking out the back window"
        >
          <StaticImage src="./images/rear.jpg" alt="Rear View" />
        </EmberCardSection>
        <EmberCardSection title="Nearside">
          <StaticImage src="./images/nearside.jpg" alt="Nearside Side View" />
        </EmberCardSection>
        <EmberCardSection title="Offside">
          <StaticImage src="./images/offside.jpg" alt="Offside Side View" />
        </EmberCardSection>
        <EmberCardSection title="Further Information">
          <Text>
            There is not a driver facing camera and we can’t see how you are
            driving. The front door view does show the iPad and occasionally the
            driver’s hand as they use the iPad. This is also how we’ve seen
            people using the tachograph whilst driving, using a phone whilst
            driving, or trying to reset the iPad whilst driving. None of these
            are safe and should never happen.
          </Text>
          <Text>
            CCTV is used for a variety of reasons, but some examples include:
          </Text>
          <List>
            <ListItem>
              Accidents - We’ve used CCTV to establish liability for a number of
              accidents. In one particular case a car driver said we had driven
              into them and it turns out they had driven into us. This was
              clearly visible from CCTV and established that we were not at
              fault.
            </ListItem>
            <ListItem>
              Vandalism - We've seen people trying to get in to the coach and
              been able to check for damage to the coach or charger on
              Greenmarket. We’ve also used the CCTV to report images to Dundee
              Council of people tampering with the charger.
            </ListItem>
            <ListItem>
              General Safety - General safety around the vehicle. Identifying
              dodgy people loitering around the coach. We’ve made drivers aware
              to look out for potentially problematic people in the area.
            </ListItem>
            <ListItem>
              Passenger Queries - We’ve used the CCTV to establish that we have
              been calling at the right stop at the right time. This has allowed
              us to prove to passengers that we were really there despite them
              insisting they were waiting. It has usually turned out to be that
              the passenger was at the wrong stop.
            </ListItem>
            <ListItem>
              Lost luggage - We’ve used the footage to find out who was sat in a
              particular seat and have then contacted that passenger to let them
              know they have left something behind.
            </ListItem>
            <ListItem>
              Bad behaviour - We’ve used the footage to identify anti social
              behaviour and have contacted customers to tell them it’s not
              acceptable and that they won’t be allowed to travel if it
              continues.
            </ListItem>
          </List>
          <Text>
            This is not an exhaustive list but gives an idea of what we use the
            system for.
          </Text>
          <Text>
            Each coach has stickers onboard alerting both staff and customers of
            the use of CCTV. These have links to our privacy policy
            (https://www.ember.to/privacy-policy/) which cover the reasons for
            use and the data retention policy. If a passenger asks for more
            information you should always direct them to the privacy policy.
          </Text>
          <Text>
            In brief, we keep the data for up to 90 days to give us time to
            respond to slow claims or any requests from police or other
            authorities. After 90 days (or sooner) the data is deleted and no
            longer retrievable.
          </Text>
        </EmberCardSection>
      </EmberCard>
    </Container>
  </DriveLayout>
)

export default Page
